import dayjs from "dayjs";
import {Badge, Button, Col, Form, InputGroup} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import FormSelect from "react-bootstrap/FormSelect";
import FormCheck from "react-bootstrap/FormCheck";
import React from "react";
import {IoIosArrowDropdown, IoIosArrowDropup} from "react-icons/all";
import DatePicker from "react-datepicker";
import { TimePicker } from "antd";
import {PickLanguageDialog} from "../global/PickLanguageDialog";
import {
    customDatePickerHeader, openExternalTargetInNewTab,
    transformToDatepickerFormat,
    transformToLocalDate,
    transformToZonedDateTime
} from "../util/ResourceService";
import {InfoModal} from "./ModalGenerator";
import CodeMirror from "@uiw/react-codemirror";
import { materialLight } from '@uiw/codemirror-theme-material';
import { json } from '@codemirror/lang-json';

//Details
export function DetailsSection(props) {

    const labelStyle = {
        fontSize: "20px",
        backgroundColor: "#C8C8C8",
        borderStyle: "solid",
        borderColor: "#cdd7e0",
        borderWidth: "1px",
        borderRadius: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        width: "100%"
    };

    //Get the data
    let data = props.state[props.nameInState];

    //Get the id of the resource in order to check if it's newly created
    let resourceId = data.id;

    //Validation errors
    let validationErrors = props.state.validationErrors != null ?
        props.state.validationErrors : [];

    //Generate the label of the section
    let sectionLabel = null;
    if (props.label != null) {
        sectionLabel = (
            <div className="details-section" onClick={() => {
                //Get the state and its sections
                let state = props.state;
                let sections = state.sections;

                //Update the visibility
                sections[props.sectionId] = !sections[props.sectionId];

                //Update the state
                state["sections"] = sections;
                props.onSetState(state);
            }}>
                <hr/>
                <h4>{props.label}
                    <span style={{float: "right", marginRight: "10px", cursor: "pointer"}}>
                        {props.state.sections[props.sectionId] ? <IoIosArrowDropup/> : <IoIosArrowDropdown/>}
                    </span>
                </h4>
                <hr/>
            </div>
        );
    }

    let rowNumber = 1;
    let fieldNumber = 1;

    return (

        <div>
            {/* Show the label if its defined */}
            {sectionLabel}

            {/* Show the data only if the tab is expanded */}
            {props.state.sections[props.sectionId] &&
            <>
                <Form key={"form"}>
                    {props.fields.map(row => (
                        <Row className={"details-row"} key={"details-row-" + rowNumber++}>
                            {row.map(field => (
                                <>
                                    {/*Make sure a field is only shown if it's not an expert field or the expert mode is active */}
                                    {((field.isExpertField == null || field.isExpertField === false) || (field.isExpertField === true && props.state.editMode.expertMode)) &&
                                    <Form.Group as={Col} controlId={field.id} key={field.id + "-" + fieldNumber++} style={{minHeight: "67px"}}>
                                        <Form.Label style={labelStyle}>{field.label}{field.required ? "*" : ""}</Form.Label>

                                        {/* Number */}
                                        {field.type === 'number' &&
                                        <Form.Control type={field.type} readOnly={!props.state.editMode.active}
                                                      disabled={(field.editable === 'never' || (field.editable === 'new' && resourceId != null))}
                                                      value={resolve(field.id, data) ? resolve(field.id, data) : ""}
                                                      isInvalid={validationErrors.indexOf(field.id) > -1}
                                                      onChange={(e) => updateField(props, field.id, e.target.value, field.trackChanges)}
                                                      onBlur={(e) => ((props.state.editMode.autoSave && props.state.editMode.active) ? props.onUpdateResource(props.state[props.nameInState]) : null)}
                                        />
                                        }

                                        {/* Text */}
                                        {field.type === 'text' &&
                                        <Form.Control readOnly={!props.state.editMode.active}
                                                      disabled={(field.editable === 'never' || (field.editable === 'new' && resourceId != null))}
                                                      value={resolve(field.id, data) ? resolve(field.id, data) : ""}
                                                      isInvalid={validationErrors.indexOf(field.id) > -1}
                                                      onChange={(e) => updateField(props, field.id, e.target.value, field.trackChanges)}
                                                      onBlur={(e) => ((props.state.editMode.autoSave && props.state.editMode.active) ? props.onUpdateResource(props.state[props.nameInState]) : null)}
                                        />
                                        }

                                        {/* Date */}
                                        {field.type === 'date' &&
                                        <DatePicker readOnly={!props.state.editMode.active}
                                                    wrapperClassName="datePicker"
                                                    renderCustomHeader={({
                                                                             date,
                                                                             changeYear,
                                                                             changeMonth,
                                                                             decreaseMonth,
                                                                             increaseMonth,
                                                                             prevMonthButtonDisabled,
                                                                             nextMonthButtonDisabled
                                                                         }) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                                    disabled={(field.editable === 'never' || (field.editable === 'new' && resourceId != null) || (field.editable === 'true' && !props.state.editMode.active))}
                                                    selected={transformToDatepickerFormat(resolve(field.id, data))}
                                                    onChange={(date) => updateDateField(props, field.id, date, field.trackChanges)}
                                                    todayButton={"Today"}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onBlur={(e) => ((props.state.editMode.autoSave && props.state.editMode.active) ? props.onUpdateResource(props.state[props.nameInState]) : null)}
                                        />
                                        }

                                        {/* Datetime */}
                                        {field.type === 'datetime' &&
                                        <DatePicker readOnly={!props.state.editMode.active}
                                                    wrapperClassName="datePicker"
                                                    renderCustomHeader={({
                                                                             date,
                                                                             changeYear,
                                                                             changeMonth,
                                                                             decreaseMonth,
                                                                             increaseMonth,
                                                                             prevMonthButtonDisabled,
                                                                             nextMonthButtonDisabled
                                                                         }) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                                    disabled={(field.editable === 'never' || (field.editable === 'new' && resourceId != null))}
                                                    selected={transformToDatepickerFormat(resolve(field.id, data))}
                                                    onChange={(date) => updateDateTimeField(props, field.id, date, field.trackChanges)}
                                                    todayButton={"Today"}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    onBlur={(e) => ((props.state.editMode.autoSave && props.state.editMode.active) ? props.onUpdateResource(props.state[props.nameInState]) : null)}
                                        />
                                        }

                                        {/* time */}
                                        {field.type === 'time' &&
                                                <TimePicker defaultValue={dayjs('12:00', 'HH:mm')} format={'HH:mm'}
                                                            disabled={(field.editable === 'never' || (field.editable === 'new' && resourceId != null))}
                                                            value={dayjs(resolve(field.id, data) ? resolve(field.id, data) : "")}
                                                            readOnly={!props.state.editMode.active}
                                                />
                                        }

                                        {/* timerange */}
                                        {field.type === 'timerange' &&
                                                <TimePicker.RangePicker
                                                        size="large"
                                                        inputReadOnly={!props.state.editMode.active}
                                                        format={'HH:mm'}
                                                        allowEmpty={true}
                                                        disabled={!props.state.editMode.active || field.editable === 'never' || (field.editable === 'new' && resourceId != null)}
                                                        onChange={(values) => updateTimeRangeValue(props, field.id1, field.id2, values)}
                                                        value={[resolve(field.id1, data) ? dayjs(resolve(field.id1, data), 'HH:mm') : null,
                                                            resolve(field.id2, data) ? dayjs(resolve(field.id2, data), 'HH:mm') : null]}
                                                />
                                        }

                                        {/* Sub */}
                                        {field.type === 'sub' &&
                                        <Form.Control readOnly={!props.state.editMode.active}
                                                      disabled={[(field.editable === 'never' || (field.editable === 'new' && resourceId != null)), (field.editable === 'never' || (field.editable === 'new' && resourceId != null))]}
                                                      value={data[field.id] != null ? data[field.id][field.subId] : ""}
                                                      onChange={(e) => updateSubField(props, field.id, field.subId, e.target.value, field.trackChanges)}
                                                      onBlur={(e) => ((props.state.editMode.autoSave && props.state.editMode.active) ? props.onUpdateResource(props.state[props.nameInState]) : null)}
                                        />
                                        }

                                        {/* Sub Sub */}
                                        {field.type === 'subsub' &&
                                        <Form.Control readOnly={!props.state.editMode.active}
                                                      disabled={(field.editable === 'never' || (field.editable === 'new' && resourceId != null))}
                                                      value={data[field.id] != null ? data[field.id][field.subId][field.subSubId] : ""}
                                                      onChange={(e) => updateSubSubField(props, field.id, field.subId, field.subSubId, e.target.value, field.trackChanges)}
                                                      onBlur={(e) => ((props.state.editMode.autoSave && props.state.editMode.active) ? props.onUpdateResource(props.state[props.nameInState]) : null)}
                                        />
                                        }

                                        {/* Textarea */}
                                        {(field.type === 'textarea') &&
                                        <Form.Control as="textarea" rows={field.rows}
                                                      readOnly={!props.state.editMode.active}
                                                      disabled={(field.editable === 'never' || (field.editable === 'new' && resourceId != null))}
                                                      value={resolve(field.id, data) ? resolve(field.id, data) : ""}
                                                      onChange={(e) => updateField(props, field.id, e.target.value, field.trackChanges)}
                                                      onBlur={(e) => ((props.state.editMode.autoSave && props.state.editMode.active) ? props.onUpdateResource(props.state[props.nameInState]) : null)}
                                        />
                                        }

                                        {/* Select */}
                                        {field.type === 'select' &&
                                        <FormSelect readOnly={!props.state.editMode.active || (field.editable === 'never' || (field.editable === 'new' && resourceId != null))}
                                                      style={{cursor: "pointer"}}

                                                      disabled={!props.state.editMode.active || (field.editable === 'never' || (field.editable === 'new' && resourceId != null))}
                                                      value={resolve(field.id, data) ? resolve(field.id, data) : ""}
                                                      onChange={(e) => updateField(props, field.id, e.target.value, field.trackChanges)}
                                                      onBlur={(e) => ((props.state.editMode.autoSave && props.state.editMode.active) ? props.onUpdateResource(props.state[props.nameInState]) : null)}>

                                            {field.options.map(option => (
                                                <option key={field.labelValueOptions ? option.value : option}
                                                        value={field.labelValueOptions ? option.value : option}>{field.labelValueOptions ? option.label : option}</option>
                                            ))}
                                        </FormSelect>
                                        }

                                        {/* Boolean */}
                                        {field.type === 'boolean' &&
                                        <FormCheck type="switch" readOnly={!props.state.editMode.active}
                                                   disabled={!props.state.editMode.active || (field.editable === 'never' || (field.editable === 'new' && resourceId != null))}
                                                   value={resolve(field.id, data) ? resolve(field.id, data) : ""}
                                                   checked={resolve(field.id, data) ? resolve(field.id, data) : false}
                                                   onChange={(e) => updateField(props, field.id, !data[field.id], field.trackChanges)}
                                                   onBlur={(e) => ((props.state.editMode.autoSave && props.state.editMode.active) ? props.onUpdateResource(props.state[props.nameInState]) : null)}>
                                        </FormCheck>
                                        }
                                        {/* Boolean Array*/}
                                        {field.type === 'booleanArray' &&
                                                <>
                                                {field.allValues.map((value) =>
                                                    <FormCheck type="checkbox"
                                                               inline={field.inline}
                                                               readOnly={!props.state.editMode.active}
                                                        disabled={!props.state.editMode.active || (field.editable === 'never' || (field.editable === 'new' && resourceId != null))}
                                                        label={value ? value : ""}
                                                        checked={checkboxIsChecked(field.id, data, value)}
                                                        onChange={(e) => updateArrayField(props, field.id, value, e, field.trackChanges)}
                                                        onBlur={(e) => ((props.state.editMode.autoSave && props.state.editMode.active) ? props.onUpdateResource(props.state[props.nameInState]) : null)}>
                                                    </FormCheck>
                                            )}
                                                </>

                                        }
                                        {/* Image */}
                                        {field.type === 'image' &&
                                                <>
                                                    {resolve(field.id, data) ?
                                                            <div className={"display-image-container"} style={{cursor: "pointer", display: "flex", justifyContent: "center"}}>
                                                                <img src={resolve(field.id, data)}
                                                                     alt={"Cover"} height={240}
                                                                     style={{cursor: "pointer"}}
                                                                     onClick={() => openExternalTargetInNewTab(resolve(field.id, data))}/>
                                                            </div>
                                                            :
                                                            <div style={{fontSize: "20px", textAlign: "center"}}>No preview available</div>
                                                    }
                                                </>
                                        }

                                        {/* Badge */}
                                        {field.type === 'badge' &&
                                                <>
                                                    {resolve(field.id, data) ?

                                                            <div className={"details-badge-container"}>
                                                                <Badge bg={getBadge(field, resolve(field.id, data)).style}>{resolve(field.id, data)}</Badge>
                                                            </div>
                                                            :
                                                            <p style={{fontSize: "20px", textAlign: "center"}}>No value available</p>
                                                    }
                                                </>
                                        }

                                        {/* custom */}
                                        {field.type === 'custom' &&
                                                <>
                                                    {props.customFieldContent}
                                                </>
                                        }

                                        {/* code */}
                                        {field.type === 'json' &&
                                                <CodeMirror
                                                        value={jsonToString(resolve(field.id, data))}
                                                        height="200px"
                                                        theme={materialLight}
                                                        extensions={[json()]}
                                                        onChange={(e) => updateField(props, field.id, JSON.parse(e), field.trackChanges)}
                                                />
                                        }

                                        {/* language selector */}
                                        {field.type === 'language' &&
                                                <>
                                                <InputGroup>
                                                    <Form.Control readOnly={!props.state.editMode.active}
                                                                  disabled={(field.editable === 'never' || (field.editable === 'new' && resourceId != null))}
                                                                  value={resolve(field.id, data) ? resolve(field.id, data) : ""}
                                                                  isInvalid={validationErrors.indexOf(field.id) > -1}
                                                                  onChange={(e) => updateField(props, field.id, e.target.value, field.trackChanges)}
                                                                  onBlur={(e) => ((props.state.editMode.autoSave && props.state.editMode.active) ? props.onUpdateResource(props.state[props.nameInState]) : null)}
                                                    />

                                                    {props.state.editMode.active &&
                                                            <Button variant="outline-secondary"
                                                                    onClick={() => {
                                                                        let state = props.state;
                                                                        state.showLanguageDialog = true;
                                                                        props.onSetState(state);
                                                                    }}>Pick</Button>
                                                    }
                                                </InputGroup>
                                                <InfoModal show={props.state.showLanguageDialog}
                                                    onHide={() => {
                                                        let state = props.state;
                                                        state.showLanguageDialog = false;
                                                        props.onSetState(state);
                                                    }}
                                                    title={"Pick a Language"}
                                                    body={<PickLanguageDialog
                                                            onClickLanguage={(language) => {
                                                                let state = props.state;
                                                                state.showLanguageDialog = false;
                                                                props.onSetState(state);
                                                                updateField(props, field.id, language.toLowerCase(), field.trackChanges)
                                                            }
                                                            }
                                                    />}/>
                                                </>
                                        }

                                        <Form.Control.Feedback type="invalid">
                                            The field '{field.label}' is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    }


                                </>
                            ))}
                        </Row>
                    ))}
                </Form>

                <InfoModal
                        key={"modal-1"}
                    show={props.state.showValidationError}
                    onHide={() => {
                        let state = props.state;
                        state.showValidationError = false;
                        props.onSetState(state);
                    }
                    }
                    size="m"
                    title={"Missing requirements"}
                    body={<div style={{textAlign: "center"}}>There are missing fields {props.state.validationErrors} that are marked as mandatory.<br/>Please
                        check your input.</div>}
                />


            </>
            }


        </div>
);
}

//HELPER
function jsonToString(data) {
    return data ? JSON.stringify(data, null, 2) : "";
}

function getBadge(field, data) {
    const badge = field
            .options[field
            .options.findIndex(x => x.value === data)];
    return badge;
}

function resolve(path, obj) {
    return path.split('.').reduce(function(prev, curr) {
        return prev ? prev[curr] : ""
    }, obj ? obj : "" || this )
}

function updateField(props, fieldId, value, trackChanges = false) {
    //Get the current state
    let state = props.state;

    //Get the resource
    let resource = state[props.nameInState];

    //Update the field of the resource
    resource[fieldId] = value;

    //Update the validation errors
    state.validationErrors = state.validationErrors ? state.validationErrors.filter(f => f !== fieldId) : [];

    //Update the state
    state[props.nameInState] = resource;
    props.onSetState(state);

    if (trackChanges && props.onTrackedFieldUpdated != null) {
        props.onTrackedFieldUpdated(fieldId);
    }
}

function checkboxIsChecked(fieldId, data, checkBoxValue) {
    let checkedArray = resolve(fieldId, data);
    if (checkedArray && Array.isArray(checkedArray)) {
        return checkedArray.includes(checkBoxValue);
    }
    return false;
}

function updateArrayField(props, fieldId, value, event, trackChanges = false) {
    //Get the current state
    let state = props.state;

    //Get the resource
    let resource = state[props.nameInState];

    //Update the field of the resource
    let arr = resource[fieldId];
    const index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }

    if (event.target.checked) {
        resource[fieldId].push(value);
    }

    //Update the validation errors
    state.validationErrors = state.validationErrors ? state.validationErrors.filter(f => f !== fieldId) : [];

    //Update the state
    state[props.nameInState] = resource;
    props.onSetState(state);

    if (trackChanges && props.onTrackedFieldUpdated != null) {
        props.onTrackedFieldUpdated(fieldId);
    }
}

function updateDateField(props, fieldId, value, trackChanges = false) {
    //Get the current state
    let state = props.state;

    //Get the resource
    let resource = state[props.nameInState];

    //Update the field of the resource
    if (value) {
        resource[fieldId] = transformToLocalDate(value.toString());
    } else {
        resource[fieldId] = null;
    }

    //Update the validation errors
    state.validationErrors = state.validationErrors ? state.validationErrors.filter(f => f !== fieldId) : [];

    //Update the state
    state[props.nameInState] = resource;
    props.onSetState(state);

    if (trackChanges && props.onTrackedFieldUpdated != null) {
        props.onTrackedFieldUpdated(fieldId);
    }
}

function updateDateTimeField(props, fieldId, value, trackChanges = false) {
    //Get the current state
    let state = props.state;

    //Get the resource
    let resource = state[props.nameInState];

    //Update the validation errors
    state.validationErrors = state.validationErrors ? state.validationErrors.filter(f => f !== fieldId) : [];

    //Update the field of the resource
    if (value) {
        resource[fieldId] = transformToZonedDateTime(value.toString());
    } else {
        resource[fieldId] = null;
    }

    //Update the state
    state[props.nameInState] = resource;
    props.onSetState(state);

    if (trackChanges && props.onTrackedFieldUpdated != null) {
        props.onTrackedFieldUpdated(fieldId);
    }
}

function updateTimeRangeValue(props, fieldId1, fieldId2, value, trackChanges = false) {
    //Get the current state
    let state = props.state;

    //Get the resource
    let resource = state[props.nameInState];

    //Update the field of the resource
    if (value[0]) {
        resource[fieldId1] = value[0].format("HH:mm");
    } else {
        resource[fieldId1] = null;
    }
    if (value[1]) {
        resource[fieldId2] = value[1].format("HH:mm");
    } else {
        resource[fieldId2] = null;
    }

    //Update the state
    state[props.nameInState] = resource;
    props.onSetState(state);

    if (trackChanges && props.onTrackedFieldUpdated != null) {
        props.onTrackedFieldUpdated(fieldId1);
        props.onTrackedFieldUpdated(fieldId2);
    }
}



function updateSubField(props, fieldId, fieldSubId, value, trackChanges = false) {
    //Get the current state
    let state = props.state;

    //Get the resource
    let resource = state[props.nameInState];

    //Get the sub resource
    let subResource = resource[fieldId];

    //Update the field of the resource
    subResource[fieldSubId] = value;

    //Update the resource
    resource[fieldId] = subResource;

    //Update the state
    state[props.nameInState] = resource;
    props.onSetState(state);

    if (trackChanges && props.onTrackedFieldUpdated != null) {
        props.onTrackedFieldUpdated(fieldId);
    }
}

function updateSubSubField(props, fieldId, fieldSubId, fieldSubSubId, value, trackChanges = false) {
    //Get the current state
    let state = props.state;

    //Get the resource
    let resource = state[props.nameInState];

    //Get the sub resource
    let subResource = resource[fieldId];

    //Get the sub sub resource
    let subSubResource = subResource[fieldSubId];

    //Update the field of the resource
    subSubResource[fieldSubSubId] = value;

    subResource[fieldSubId] = subSubResource;

    //Update the resource
    resource[fieldId] = subResource;

    //Update the state
    state[props.nameInState] = resource;
    props.onSetState(state);

    if (trackChanges && props.onTrackedFieldUpdated != null) {
        props.onTrackedFieldUpdated(fieldId);
    }
}

export function RequiredFieldsAreValid(resourceName, sections, state, setState) {
    if (resourceName == null || state == null || state[resourceName] == null || sections == null) {
        return true;
    } else if (Array.isArray(sections) && sections.length === 0) {
        return true;
    }

    //Convert the sections to an array (if this isn't already the case)
    if (!Array.isArray(sections)) {
        console.log("IS ARRAY");
        sections = Array.of(sections);
    }

    console.log(sections);

    let validationErrors = [];
    const resource = state[resourceName];
    sections.forEach(row => {
        if (row) {
            row.forEach(column => {
                let missingRequirements = [];
                if (Array.isArray(column)) {
                    missingRequirements = column
                            .filter(c => c.required)
                            .filter(c => c.editable === "true" || c.editable === "new")
                            .filter(c => resource[c.id] === undefined || resource[c.id] == null || resource[c.id] === "")
                            .map(c => c.id);
                } else {
                    missingRequirements = (column.required && (column.editable === "true" || column.editable === "new") && (resource[column.id] === undefined || resource[column.id] == null || resource[column.id] === "")) ? [column.id] : [];
                }

                validationErrors = validationErrors.concat(missingRequirements);
            })
        }
    });

    console.log(validationErrors);

    if (validationErrors.length > 0) {
        state.validationErrors = validationErrors;
        state.showValidationError = true;
        setState(state);
        return false;
    } else {
        return true;
    }
}